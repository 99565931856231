<template>
    <div class="card">
        <div class="card-body">
            <div class="row" v-if="info.name">
                <div class="col-lg-3 col-xl-3 col-md-6 col-sm-12 mb-2">
                    <div class="media">
                        <div class="mr-2">
                            <b-avatar :src="info.logo" size="lg"  v-if="info.logo" ></b-avatar>
                            <b-avatar v-else variant="primary" size="lg" :text="(info.name).substr(0, 1)" class="align-baseline "></b-avatar>

                        </div>
                        <div class="media-body align-self-center">
                            <div class="text-muted">
                                <h5 class="mb-1" >{{info.name}}</h5>
                                <p> {{info.time_diff}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-6 col-md-6  col-sm-12  mb-2">

                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-4 m-xl-auto m-lg-auto col-sm-6 mb-2  w-50">
                                    <p class="text-muted  mb-2">{{$t('profil_comp.type')}}</p>
                                    <h5 class="mb-0">{{info.type}}</h5>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 m-xl-auto m-lg-auto col-sm-6 mb-2  w-50">

                                    <p class="text-muted  mb-2">{{$t('profil_comp.teams')}}</p>
                                    <h5 class="mb-0">{{info.teams_count}}</h5>

                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4 m-xl-auto m-lg-auto col-sm-6 mb-2  w-50" >

                                    <p class="text-muted mb-2">{{$t('profil_comp.start')}}</p>
                                    <h6 class="mb-0 font-size-14">{{info.begin_at}}</h6>

                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4 m-xl-auto m-lg-auto col-sm-6 mb-2  w-50" >

                                    <p class="text-muted mb-2">{{$t('profil_comp.end')}}</p>
                                    <h6 class="mb-0 font-size-14">{{info.finish_at}}</h6>

                            </div>

                        </div>
                </div>

                <div class="col-lg-3 col-xl-3 text-center m-auto" >
                    <div class="row">
                        <div class="col-xl-6 p-0">  <router-link v-if="info.type_time != 'upcoming'"
                                                            type="button"
                                                            class="btn btn-primary w-75 mb-2 btn-sm btn-rounded"
                                                            :to="{name:'ScoreBoardCompetition',params:{id:info.id}}"
                        >{{$t('profil_comp.scoreboard')}}</router-link></div>
                        <div class="col-xl-6 p-0">            <router-link  v-if="info.type_time != 'upcoming'"
                                                                       type="button"
                                                                       class="btn w-75 mb-2 btn-primary btn-sm btn-rounded"
                                                                       :to="{name:'statisticsCompetition',params:{id:info.id}}"
                        >{{$t('profil_comp.statistics')}}</router-link></div>
                        <div class="col-xl-6 p-0" v-if="info.is_joined && info.type_time=='live'"><router-link
                                                      :to="{ name: 'Competition', params: { id: info.id }}"
                                                      type="button" class="btn btn-primary w-75 mb-2 btn-sm btn-rounded"
                        >{{$t('profil_comp.access')}}</router-link></div>
                        <div class="col-xl-6 p-0" v-if="info.is_joined == 1 && info.type_time != 'passed'">      <button
                                 @click="$bvModal.show('unjoin')"
                                type="button"
                                class="btn btn-primary w-75 mb-2 btn-sm btn-rounded"> {{$t('dashboard.comp.unjoin')}}</button></div>
                        <div class="col-xl-6 p-0" v-if="info.is_joined == 0 && info.type_time != 'passed'">     <button
                                                           @click="Join()"
                                                           type="button"
                                                           class="bt        n btn-primary w-75 mb-2 btn-sm btn-rounded"
                        >{{$t('profil_comp.join')}}</button></div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-xl-2">
                    <div class="row">
                        <b-skeleton type="avatar" class="col-sm-2 mr-2"></b-skeleton>
                        <div class="col-sm-9">
                            <b-skeleton class="mb-2"></b-skeleton>
                            <b-skeleton class="mb-2"></b-skeleton>
                        </div>
                    </div>


                </div>
                <div class="col-xl-2">
                    <p class="text-muted   mb-2">{{$t('profil_comp.type')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2">
                    <p class="text-muted mb-2">{{$t('profil_comp.teams')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2">
                    <p class="text-muted  mb-2">{{$t('profil_comp.start')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-2">
                    <p class="text-muted  mb-2">{{$t('profil_comp.end')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-1">
                    <b-skeleton type="button" class="mt-2"></b-skeleton>
                </div>
                <div class="col-xl-1">
                    <b-skeleton type="button" class="mt-2"></b-skeleton>
                </div>

            </div>
            <unjoin-confirmation :id="$route.params.id" type="competitions" v-on:refresh="refreshUnjoin"/>
        </div>
    </div>
</template>
<script>

    import helper from "@/assets/helpers/helper";
    import UnjoinConfirmation from "@/components/popups/unjoinconfirmation";

    export default {
        name: "profileCompHeader",
        components: {UnjoinConfirmation},
        props:['info'],
        data(){
            return{
                selectedType:'',
            }
        },
        methods:{
            refreshUnjoin(){
                this.info.is_joined = 0
                this.info.teams_count -= 1;
                if(this.info.scope == 'private') this.$router.push({ name: 'Competitions'})
            },
            async Join(){
                        let r = await helper.postReturnData('competitions/'+this.$route.params.id+'/join');
                        if(r){
                            this.info.is_joined  = 1;
                            this.info.teams_count += 1;
                            if(this.info.type_time == 'live') await this.$router.push({name:'Competition',params:{id:this.$route.params.id}})

                        }


            },
        }


    }
</script>

<style scoped>


</style>