<script>

    import appConfig from "@/app.config.json";
    import ProfileCompHeader from "@/components/profileCompHeader";
    import helper from "@/assets/helpers/helper";
    import PartnersSponsors from "../../components/partners-sponsors";

    export default {
        page: {
            title: "Profile Competition",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'ProfilCompetition',
        components: {PartnersSponsors, ProfileCompHeader},
        data() {
            return {
                title: "Profile Competition",
                profil_info:{},
                load:false,
                is_part:true,
            };
        },
        created(){
            this.loadCompetition()
        },
        methods: {
            async loadCompetition() {
                let comp = await helper.getItem('competitions', this.$route.params.id);
                this.load = true
                this.profil_info = comp

            },
        }
    };
</script>

<template>
    <div>

        <div class="row">
            <div class="col-lg-12">
                <profile-comp-header :info="profil_info"></profile-comp-header>

            </div>
        </div>
        <div class="row " >
            <div  class="col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <ul class="verti-timeline list-unstyled">

                                <li class="event-list">
                                    <div class="event-timeline-dot">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div class="media">
                                        <div class="mr-3">
                                            <i class="bx bx-map-pin h2 text-primary"></i>
                                        </div>
                                        <div class="media-body">
                                            <div>
                                                <h5 class="font-size-14">{{$t('profil_comp.location')}}</h5>
                                                <p class="text-muted">
                                                    {{profil_info.location}}
                                                </p>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="event-list">
                                    <div class="event-timeline-dot">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div class="media">
                                        <div class="mr-3">
                                            <i class="bx bxs-contact h2 text-primary"></i>
                                        </div>
                                        <div class="media-body">
                                            <div>
                                                <h5 class="font-size-14">{{$t('profil_comp.contact')}}</h5>
                                                <p class="text-muted">
                                                    {{profil_info.contact}}
                                                </p>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load" width="75%"></b-skeleton>

                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="event-list">
                                    <div class="event-timeline-dot">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div class="media">
                                        <div class="mr-3">
                                            <i class="bx bx-badge-check h2 text-primary"></i>
                                        </div>
                                        <div class="media-body">
                                            <div>
                                                <h5 class="font-size-14">{{$t('profil_comp.conditions')}}</h5>
                                                <div class="text-muted mb-1" v-html="profil_info.conditions">
                                                    {{profil_info.conditions}}
                                                </div>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load" width="75%"></b-skeleton>

                                            </div>
                                        </div>
                                    </div>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4" >
                <div class="card">
                    <div class="card-body">

                        <div>
                            <ul class="verti-timeline list-unstyled">
                                <li class="event-list">
                                    <div class="event-timeline-dot">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div class="media">
                                        <div class="mr-3">
                                            <i class="bx bx-receipt h2 text-primary"></i>
                                        </div>
                                        <div class="media-body">
                                            <div>
                                                <h5 class="font-size-14">{{$t('profil_comp.description')}}</h5>
                                                <div class="text-muted" v-html="profil_info.description">
                                                    {{profil_info.description}}</div>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load" width="75%"></b-skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="event-list">
                                    <div class="event-timeline-dot">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div class="media">
                                        <div class="mr-3">
                                            <i class="bx bx-trophy h2 text-primary"></i>
                                        </div>
                                        <div class="media-body">
                                            <div>
                                                <h5 class="font-size-14">{{$t('profil_comp.prizes')}}</h5>
                                                <div class="text-muted" v-html="profil_info.prizes">
                                                   {{profil_info.prizes}}
                                                </div>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load"></b-skeleton>
                                                <b-skeleton v-if="!load" width="75%"></b-skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-xl-4">
                <partners-sponsors/>

            </div>
         </div>
        <!-- end row -->
    </div>
</template>
<style scoped>
    .card{
        height: 94%;
    }
</style>