<template>
    <div class="card">
        <div class="card-body">
            <h4 class="mt-3">{{$t('partener.title')}}</h4>
            <p class="text-muted mb-2" v-if="partners.length"> {{$t('partener.p')}}</p>
            <b-list-group >
<!--                <b-list-group-item>-->
<!--                    <b-avatar href="#foo" variant="primary" text="FF" class="align-baseline mr-3"></b-avatar>-->
<!--                    Link Text Avatar-->
<!--                </b-list-group-item>-->
                <b-list-group-item v-for=" item in partners" :key="item.id">
                    <b-avatar :src="item.partner.logo" v-if="item.partner.logo" class=" mr-3"></b-avatar>
                    <b-avatar v-else variant="primary" :text="(item.partner.name).substr(0, 1)" class="align-baseline mr-3"></b-avatar>
                    {{item.partner.name}}
                </b-list-group-item>

            </b-list-group>
            <p  v-if="partners.length == 0 && load" class="text-center">
                {{$t('validation.no_partener')}} <br/>
                <span role="button" class="mt-2 font-weight-medium text-primary"  v-b-modal.modal-collaborate>{{$t('validation.contact')}}</span>
            </p>

        </div>
    </div>
</template>

<script>
    import helper from "../assets/helpers/helper";

    export default {
        name: "partners-sponsors",
        data(){
            return{
                load:false,
                partners:[]
            }
        },
        created(){
           this.loadData()
        },
        methods:{
         async  loadData(){
             this.partners = await helper.getAll('competitions/'+this.$route.params.id+'/partners');
             this.load = true
            }
        }
    }
</script>

<style scoped>

</style>